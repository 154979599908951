<style scoped>
.page-content {
  background: #fff;
  padding-top: 0;
}
.bg-container {
  position: relative;
}
.bg-container .image {
  width: 100%;
  min-height: 180px;
}
.bg-container .empty {
  width: 100%;
  min-height: 180px;
}
.bg-container .menu {
  position: absolute;
  top: 10px;
  left: 15px;
  border-radius: 5px;
  background: #fff;
}
.container {
  border-radius: 10px 10px 0 0;
  background: #fff;
  margin-top: -30px;
  position: relative;
}
.title-container {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}
.title-container .logo {
  margin-right: 15px;
}
.title-container .logo .image,
.title-container .logo .empty {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.title-container .title-content .name {
  font-size: 20px;
}
.title-container .title-content .store-name {
  font-size: 14px;
  margin: 10px 0;
}
.title-container .title-content .no {
  font-size: 12px;
}
.second-row,
.third-row {
  display: flex;
  justify-content: space-around;
  font-size: 15px;
  color: rgba(153, 153, 153, 1);
}
.second-row .row {
  margin-top: 10px;
  justify-content: center;
}
.second-row .row:last-child {
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-weight: 600;
}
.third-row {
  margin-top: 30px;
}
.col-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 40%;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
  font-size: 13px;
}
.col-item .price {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
</style>
<template>
  <div class="page">
    <div class="page-content">
      <div class="bg-container">
        <img
          :src="clientInfo.qtzpath"
          class="image"
          v-if="clientInfo.qtzpath"
        />
        <div class="empty" v-else></div>
        <div class="menu panel-open" data-panel="left">
          <i class="f7-icons icon">menu</i>
        </div>
      </div>
      <div class="container">
        <div class="title-container">
          <div class="logo">
            <img
              :src="clientInfo.mtzpath"
              class="image"
              v-if="clientInfo.mtzpath"
            />
            <div class="empty" v-else></div>
          </div>
          <div class="title-content">
            <div class="name">{{ clientInfo.clientname || "" }}</div>
            <div class="store-name">{{ clientInfo.licencename || "" }}</div>
            <div class="no">商户号：{{ clientInfo.reserved5 || "" }}</div>
          </div>
        </div>
        <div class="second-row">
          <div class="col" @click="$f7.popup.open('.popup-alert')">
            <div class="row">关注</div>
            <div class="row">{{ attentionInfo.attentionNum }}</div>
          </div>
          <div class="col">
            <div class="row">点赞</div>
            <div class="row">{{ attentionInfo.praiseNum }}</div>
          </div>
          <div class="col">
            <div class="row">收藏</div>
            <div class="row">{{ attentionInfo.collectionNum }}</div>
          </div>
          <div class="col">
            <div class="row">反馈</div>
            <div class="row">0</div>
          </div>
        </div>
        <div class="third-row">
          <div class="col-item" @click="$f7.popup.open('.popup-alert')">
            <div class="row">熟客电商</div>
            <div class="row price">同步商家商品</div>
            <div class="row">查看收款明细</div>
          </div>
          <div class="col-item" @click="$f7.popup.open('.popup-alert')">
            <div class="row">今日收款</div>
            <div class="row price">{{ price }}元</div>
            <div class="row">电商销售0佣金</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      price: 0,
      attentionInfo: {
        attentionNum: 0,
        collectionNum: 0,
        praiseNum: 0,
      },
    };
  },
  computed: {
    clientInfo() {
      return (
        this.$root.$children[0].userInfo.clientList[
          this.$root.$children[0].selectedIndex
        ] || { reserved5: "" }
      );
    },
    id() {
      return this.clientInfo.id || "";
    },
  },
  watch: {
    id() {
      this.getValue(this.clientInfo);
      this.fetchPrice(this.clientInfo);
    },
  },
  mounted() {},
  methods: {
    async getValue(clientInfo) {
      if (!clientInfo.id) {
        this.attentionInfo = {
          attentionNum: 0,
          collectionNum: 0,
          praiseNum: 0,
        };
        return;
      }
      const formData = new FormData();
      formData.append("clientId", clientInfo.id);
      const response = await fetch(`/ShuKeManage/SignController/getAttentionInfo`, {
        method: "post",
        body: formData,
      });
      const jsonData = await response.json();
      if (jsonData.code === "0000") {
        this.attentionInfo = jsonData.data;
        return;
      }
      this.attentionInfo = {
        attentionNum: 0,
        collectionNum: 0,
        praiseNum: 0,
      };
    },
    async fetchPrice(clientInfo) {
      if (!clientInfo.id) {
        this.price = 0;
        return;
      }
      const now = new Date();
      const month = `0${now.getMonth()}`.slice(-2);
      const day = `0${now.getDate()}`.slice(-2);
      const dayS = `${now.getFullYear()}-${month}-${day} 00:00:00`;
      const dayE = `${now.getFullYear()}-${month}-${day} 23:59:59`;
      const formData = new FormData();
      formData.append("merchantno", clientInfo.reserved5);
      formData.append("state", 210);
      formData.append("orderdateS", dayS);
      formData.append("orderdateE", dayE);
      const response = await fetch(
        `/ShuKeManage/ClientIphoneTitleController/app_tradingsummarys`,
        {
          method: "post",
          body: formData,
        }
      );
      const jsonData = await response.json();
      if (jsonData.code === "0000") {
        this.price = jsonData.data.payTatalAmt;
        return;
      }
      this.price = 0;
    },
  },
};
</script>
