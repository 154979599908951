<style scoped>
#panel-nested .page-content {
  background: #fff;
}
#panel-nested .navbar {
  color: #fff;
}
#panel-nested .navbar-bg {
  background: rgba(51, 51, 51, 1);
}
.list.links-list {
  margin-top: 10px;
}
.item-media .image {
  width: 20px;
}
.item-title {
  font-size: 15px;
}
.label {
  color: rgba(16, 15, 15, 1);
  font-size: 10px;
  margin-left: 5px;
  font-weight: 200;
}
.list .item-media + .item-inner {
  margin-left: 10px;
}
.list .link .item-media + .item-inner {
  padding-right: 5px;
}
.list ul:before,
.links-list a:after,
.list .item-inner:after,
.list ul:after {
  height: 0;
}
.popup-alert {
  background: rgba(119, 119, 119, 0.6);
}
.popup-alert .container {
  margin: 150px 30px;
}
.container .logo {
  text-align: center;
  margin-bottom: 10px;
  position: relative;
}
.container .logo .image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.container .logo .icon {
  position: absolute;
  right: 30px;
  top: -30px;
  color: #fff;
}
.container .title {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 50px;
}
.popup-alert .open-button {
  background: rgba(235, 103, 103, 1);
  height: 44px;
  line-height: 44px;
  color: #fff;
}
</style>
<template>
  <div
    class="panel panel-left panel-cover panel-init dark"
    id="panel-nested"
    data-container-el="#panel-page"
  >
    <div class="page">
      <div class="navbar dark">
        <div class="navbar-bg"></div>
        <div class="navbar-inner">
          <div class="left">
            <a href="#" class="link panel-close">
              <i class="icon icon-back color-white"></i>
            </a>
          </div>
          <div class="title">商家账户中心</div>
        </div>
      </div>
      <div class="page-content">
        <div class="list links-list">
          <ul>
            <li class="item-content">
              <div class="item-media">
                <div class="image"></div>
              </div>
              <div class="item-inner">
                <div class="item-title">商户号：{{ clientInfo.reserved5 }}</div>
                <div class="item-after">
                  <img
                    src="../assets/arrows.png"
                    class="image"
                    @click="goToPage('/StoreCenterPage')"
                  />
                </div>
              </div>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/message.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    短信工具<label class="label">最低0.05元/条 </label>
                  </div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/pos.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    收款设备<label class="label">移动支付新科技限时体验</label>
                  </div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/bank.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    银行活动<label class="label">最低0.05元/条 </label>
                  </div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="goToPage('/NotificationPage')">
                <div class="item-media">
                  <img src="../assets/voice.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    消息通知<label class="label">语音播报，公众号消息</label>
                  </div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/mini-program.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    小程序<label class="label"
                      >海量免费微信支付宝小程序模板</label
                    >
                  </div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/info.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">门店基本信息</div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/send.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    配送<label class="label"
                      >顺丰同城、商家自配，上门自提</label
                    >
                  </div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/message.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">
                    客APP限时广告<label class="label">限时在客APP中曝光 </label>
                  </div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <li>
              <a class="link" href="#" @click="showModal">
                <div class="item-media">
                  <img src="../assets/message.png" class="image" />
                </div>
                <div class="item-inner">
                  <div class="item-title">账单推送</div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    clientInfo() {
      return (
        this.$root.$children[0].userInfo.clientList[
          this.$root.$children[0].selectedIndex
        ] || { reserved5: "" }
      );
    },
  },
  methods: {
    goToPage(url) {
      this.$f7.panel.close("#panel-nested");
      this.$f7.views.main.router.navigate({ url });
    },
    showModal() {
      this.$f7.popup.open(".popup-alert");
    },
  },
};
</script>