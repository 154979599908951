<template>
  <div class="login-screen modal-in">
    <f7-view url="/login" class="login">
      <div class="page login-screen-page">
        <div class="page-content login-screen-content">
          <div class="login-screen-title">
            <img src="../assets/login-logo.png" class="image" alt="" />
          </div>
          <form>
            <div class="list inline-labels">
              <ul>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input
                        type="text"
                        placeholder="请输入手机号"
                        v-model="phone"
                      />
                      <span class="input-clear-button"></span>
                    </div>
                  </div>
                </li>
                <li class="item-content item-input">
                  <div class="item-inner">
                    <div class="item-input-wrap">
                      <input type="text" v-model="code" placeholder="验证码" />
                      <span class="input-clear-button"></span>
                    </div>
                    <div
                      :class="['item-after', this.canSend ? 'color-red' : '']"
                      @click="fetchCode"
                    >
                      {{ timeLabel }}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </form>
          <div class="block-footer">
            如果未注册用户，登录即注册<br />
            <div class="row">
              <input type="checkbox" v-model="isAgree" />登录即代表同意<label
                class="label"
                @click="toProtocolPage"
                >用户协议</label
              >和<label class="label" @click="toProtocolPage">隐私政策</label>
            </div>
          </div>
          <div class="button-row">
            <div class="button button-fill button-large" @click="login">
              登录
            </div>
          </div>
          <div class="others">
            <div class="label">其他方式登录</div>
            <div class="icon">
              <img src="../assets/wechat.png" alt="" class="image" />
            </div>
          </div>
        </div>
      </div>
    </f7-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      phone: "",
      code: "",
      isAgree: false,
      timeLabel: "获取验证码",
      canSend: true,
    };
  },
  mounted() {
    this.$root.$children[0].eventCenter.$on(
      "agree",
      (isAgree) => (this.isAgree = isAgree)
    );
  },
  methods: {
    async login() {
      if (this.phone === "" || !/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$f7.dialog.alert("请输入正确的手机号", "");
        return;
      }
      if (this.code === "") {
        this.$f7.dialog.alert("请输入验证码", "");
        return;
      }
      if (!this.isAgree) {
        this.$f7.dialog.alert("请同意用户协议与隐私政策", "");
        return;
      }
      const formData = new FormData();
      formData.append("verification_code", this.code);
      formData.append("phonenumber", this.phone);
      formData.append("code", this.$root.$children[0].code);
      formData.append("isWechat", "public");
      const response = await fetch(
        `/ShuKeManage/SignController/loginByIphone`,
        {
          method: "post",
          body: formData,
        }
      );
      const jsonData = await response.json();
      if (jsonData.state_code === "0000") {
        this.$root.$children[0].isLogin = true;
        this.$root.$children[0].userInfo = {
          id: jsonData.orderUserId,
          ...jsonData,
          clientList: [
            // {
            //   addr: "上海市闵行区联航路1505弄1号楼1307室",
            //   clientjgname: "888888",
            //   clientname: "熟客收银培训", //门店名称
            //   clientsdid: "SH29096",
            //   clienttype: 1,
            //   createdate: 1664502074000,
            //   createuser: 1,
            //   cszpath:
            //     "https://www.shuke.shop/ShuKeManage/202210201344420238884.jpg",
            //   email: "1",
            //   employeeid: 4,
            //   enabled: true,
            //   grouptype: 15,
            //   id: 48, //门店商户id（也是认证号）
            //   imgCount: 0,
            //   isdeleted: false,
            //   lat: 31.076256,
            //   licencename: "上海红增信息技术有限公司", //商户名称
            //   lng: 121.517382,
            //   mtzpath:
            //     "https://www.shuke.shop/ShuKeManage/202210201344420238884.jpg", //商户的头像路径
            //   name: "收银培训",
            //   outPhone: "13918970013",
            //   phone: "15921285681",
            //   qtzpath:
            //     "https://www.shuke.shop/ShuKeManage/202211071732220650106.jpg", //背景图片路径
            //   reserved1: 3,
            //   reserved5: "20221030121", //商户号（商户编号也是这个，前面加上SK）
            //   reserved6: "24743852", //终端号
            //   state: 4,
            //   updatedate: 1686028967000,
            //   updateuser: 1,
            //   userId: "97",
            //   wechat: "1",
            // },
            ...jsonData.clientList,
          ],
        };
        if (jsonData.clientList.length === 0) {
          this.$f7.popup.open(".popup-alert");
          return;
        }
        const index = jsonData.clientList.findIndex((item) => {
          return Number(item.id) === Number(jsonData.orderUserId);
        });
        if(index===-1){
          const formData = new FormData();
          formData.append("clientId", jsonData.clientList[0].id);
          formData.append("userId", this.$root.$children[0].userInfo.xh);
           await fetch(
            `/ShuKeManage/SignController/updatePhoneUserClient`,
            {
              method: "post",
              body: formData,
            }
          );
        }
        this.$root.$children[0].selectedIndex = index === -1 ? 0 : index;
        this.$f7.loginScreen.close();
        return;
      }
      this.$f7.dialog.alert(jsonData.return_text, "");
    },
    async fetchCode() {
      if (this.phone === "" || !/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$f7.dialog.alert("请输入正确的手机号", "");
        return;
      }
      if (!this.canSend) {
        return;
      }
      try {
        const response = await fetch(
          `/ShuKeManage/SignController/loginCode?phonenumber=${this.phone}`
        );
        const jsonData = await response.json();
        console.log(jsonData);
        this.canSend = false;
        let time = 60;
        const interval = setInterval(() => {
          if (time === 0) {
            clearInterval(interval);
            this.canSend = true;
            this.timeLabel = "发送验证码";
            return;
          }
          this.timeLabel = `${time}s`;
          time -= 1;
        }, 1000);
      } catch (e) {
        this.$f7.dialog.alert("发送验证码失败", "");
        console.log(e);
      }
    },
    toProtocolPage() {
      this.$f7.views.get(".login").router.navigate({ url: "/ProtocolPage" });
    },
  },
};
</script>
<style scoped>
.login-screen-page .login-screen-content,
.login-screen > .view > .page .login-screen-content {
  margin-top: 100px;
  padding-top: 0;
  margin-bottom: 0;
  height: 100%;
}
.login-screen-page .login-screen-title {
  margin-top: 0;
}
.login-screen-title .image {
  width: 53px;
  height: 53px;
  border-radius: 10px;
}
.others {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 100px;
  left: 0;
}
.others .label {
  position: relative;
  font-size: 15px;
  width: 100%;
  text-align: center;
}
.others .label::before,
.others .label::after {
  content: "";
  width: 30%;
  position: absolute;
  top: 10px;
  height: 1px;
  background: #000;
}
.others .label::before {
  left: 20px;
}
.others .label::after {
  right: 20px;
}

.others .image {
  margin-top: 20px;
  width: 50px;
  height: 50px;
}
.list {
  margin: 0 17px;
}
.item-input:after {
  content: "";
  position: absolute;
  background-color: var(--f7-list-item-border-color);
  display: block;
  z-index: 15;
  top: auto;
  right: auto;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 100%;
  transform-origin: 50% 100%;
  transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
}
.color-red {
  color: rgba(246, 13, 13, 1);
}
.button-row {
  margin: 0 25px;
}
.button-row .button {
  background: rgba(246, 13, 13, 1);
}
.block-footer {
  font-size: 10px;
  color: rgba(153, 153, 153, 1);
}
.block-footer .row {
  margin-top: 5px;
  justify-content: center;
  align-items: center;
}
.block-footer .label {
  color: rgba(89, 157, 240, 1);
}
</style>
